import { Fragment, useEffect, useState } from 'react'
import { indexOrganizations } from '../../../api/facturapi/Organizations';
import TableComp from '../../Layouts/Table'

const OrganizationsComp = () => {
    const [orgs, setOrgs] = useState();

    useEffect(() => {
        getOrganizations();
    }, []);

    const getOrganizations = async () => {
        let response = await indexOrganizations();
    }

    return (
        <Fragment>
            <TableComp
                headers={['#']}
            />
        </Fragment>
    )
}

export default OrganizationsComp