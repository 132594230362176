export const data_validate = [
    { key: 'CentroDesc', name: 'CentroDesc' },
    { key: 'Eknam', name: 'Eknam' },
    { key: 'Lgobe', name: 'Lgobe' },
    { key: 'Ebeln', name: 'Ebeln' },
    { key: 'Bedat', name: 'Bedat' },
    { key: 'Ebelp', name: 'Ebelp' },
    { key: 'Menge', name: 'Menge' },
    { key: 'Txz01', name: 'Txz01' },
    { key: 'Meins', name: 'Meins' },
    { key: 'Waers', name: 'Waers' },
    { key: 'Netpr', name: 'Netpr' },
    { key: 'ImpTotalPed', name: 'ImpTotalPed' },
    { key: 'CanXEntregar', name: 'CanXEntregar' },
    { key: 'ImpXEntregar', name: 'ImpXEntregar' },
];