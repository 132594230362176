import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import Main from "./fragments/Main";

const AccessControl = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/access-control' });
    }, []);

    return (
        <Main />
    );
}

export default AccessControl;