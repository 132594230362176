import { SET_ACTIVE_TAB } from '../actions/main';
import { stateTabActive as initialState } from '../initialState/main';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_TAB:
            return action.tab;
        default: return state;
    }
}

export default reducer;