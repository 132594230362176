import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../Layouts/Modal'

const Details = (props) => {
    const { onClose } = props;
    return (
        <ModalComp
            title='Detalles del cliente'
            size='lg'
            onClose={onClose}
            body={<Fragment>

            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
            </Fragment>}
        />
    )
}

export default Details