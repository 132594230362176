import React, { Fragment, useEffect, useState } from 'react'
import { indexBusinessName } from '../../../api/Cfdi/BusinessNames';
import PaginationComp from '../../Layouts/Pagination';
import TableComp from '../../Layouts/Table'

const SelectBusinessNames = (props) => {
    const { headers, fibra_id, businessNamesSel, setBusinessNamesSel } = props;
    const [businessNames, setBusinessNames] = useState([]);             // Razones sociales obtenidas
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getBusinessNames();
    }, [page, pageSize]);

    const getBusinessNames = async () => {
        setLoading(true);
        let response = await indexBusinessName({ headers, fibra_id, page, pageSize });
        setBusinessNames(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }
    const handleChange = (checked, bn_id) => {
        if (checked)
            setBusinessNamesSel([...businessNamesSel, bn_id]);
        else
            setBusinessNamesSel(businessNamesSel.filter((item) => item !== bn_id));
    }
    const getChecked = (bn_id) => {
        return businessNamesSel.includes(bn_id);
    };
    const getDisabled = () => false;
    return (
        <Fragment>
            <TableComp
                type='switch'
                headers={['#', 'Razón social', 'RFC', 'Acciones']}
                keys={['#', 'name', 'rfc']}
                body={businessNames}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                actions={{
                    checkedChildren: 'Si',
                    unCheckedChildren: 'No',
                    onChange: handleChange,
                    getChecked: getChecked,
                    getDisabled: getDisabled
                }}
            />
            <PaginationComp current={page} totalItems={totalItems} onChange={changePage} />
        </Fragment>
    )
}

export default SelectBusinessNames