import { Fragment } from 'react'

const ClientHome = () => {
	const dashboard = 'https://metabase.victum-re.online/public/dashboard/fdb3087c-7034-4684-92ba-0d0232adaf4b';

	return (
		<Fragment>
			<iframe
				src={dashboard}
				frameborder="0"
				width="100%"
				height="750"
				allowtransparency
			></iframe>
		</Fragment>
	)
}

export default ClientHome;
