import { Heading } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { SET_ACTIVE_TAB } from '../../redux/actions/main';
import TabsComp from '../Layouts/Tabs';
import ClientsComp from './Clients/Index';
import InvoicesComp from './Invoices/Index';
import OrganizationsComp from './Organizations/Index';

const ElectronicBillingAdmin = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/electronic-billing' });
    }, []);

    return (
        <Container fluid>
            <Heading className='mb-3'>Facturación electrónica</Heading>

            <TabsComp
                tablist={['Facturas', 'Clientes', 'Organizaciones',]}
                tabpanels={[
                    <InvoicesComp />,
                    <ClientsComp />,
                    <OrganizationsComp />
                ]}
            />
            
        </Container>
    )
}

export default ElectronicBillingAdmin;
