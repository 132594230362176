import Fetcher from "../../libs/Petition";

const url = '/employees';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param provider_id Se utiliza el identificador del socio comercial para obtener sus registros
 * @param page [Opcional] Se utiliza para conocer la página en la que se encuentra
 * @param pageSize [Opcional] Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @returns Object {"data" => Employee::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexEmployee = async (props) => {
    const {
        headers,
        provider_id,
        string,
        page,
        pageSize } = props;
    let response = { data: [], totalItems: 0 };
    try {
        // Parámetros requeridos
        let params = { provider_id };
        // Parámetros opcionales
        if (string) params.string = string;
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("Fetcher Employees: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param employee_id Identificador del empleado sobre el que se hará la consulta
 * @returns Employee Retorna un objeto tipo Employee
 */
export const showEmployee = async ({ headers, employee_id }) => {
    let response = {};
    try {
        let fetch = await Fetcher({
            url: `${url}/${employee_id}`,
            method: 'GET',
            headers
        });
        // console.log("Employee [show]: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeEmployee = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param employee_id Identificador del empleado que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateEmployee = async ({ headers, data, employee_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${employee_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = true;
        // console.log("Fetch complain suggestion: ", fetch);
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param employee_id Identificador del empleado que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyEmployee = async ({ headers, employee_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${employee_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const downloadEmployee = async ({ headers, employee_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/download/${employee_id}`,
            method: 'GET',
            responseType: 'blob',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = new Blob([fetch.data], { type: fetch.data.type });

    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}