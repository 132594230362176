import { Box, Image } from '@chakra-ui/react';
import { PageHeader } from 'antd';
import { Fragment, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { downloadEmployee, showEmployee } from '../../../../api/Repse/Employees';
import FormIcon from '../../../Layouts/Forms/FormIcon';
import SkeletonForm from '../../../Layouts/Skeletons/Form';
import PicProfile from '../../../../images/default_profile.jpg';

const EmployeeDetails = (props) => {
    const { employee_id, goBack, headers } = props;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = async () => {
        let response = await showEmployee({ headers, employee_id });
        let fetch = await downloadEmployee({ headers, employee_id });
        let image = PicProfile;                             //  Asignamos la imagen de perfil por default
        if (!fetch) {                                        // Si encuentra una imagen la reemplaza
            let url = window.URL || window.webkitURL;       // Creamos una URL
            image = url.createObjectURL(fetch);             // Asignamos el objeto a la URL
        }
        setUrl(image);                                      // Se guarda la url de la imagen
        setData(response);                                  // Guardar la información detallada de empleado
        setLoading(false);                                  // Indicar que se terminó de realizar la operación
    }

    return (
        <Fragment>
            {
                loading ? <SkeletonForm rows={3} cols={3} /> :
                    <Container fluid>
                        <Row>
                            <Col md={4} xs={12}>
                                <Box boxSize='xs'>
                                    <Image borderRadius='full' src={url} alt='Imagen de perfil' />
                                </Box>
                            </Col>
                            <Col md={8} xs={12}>
                                <Row className='mb-3'>
                                    <FormIcon title='Nombre(s)' value={data?.first_name} />
                                    <FormIcon title='Apellido(s)' value={data?.last_name} />
                                </Row>
                                <Row className='mb-3'>
                                    <FormIcon title='CURP' value={data?.curp} />
                                    <FormIcon title='RFC' value={data?.rfc} />
                                </Row>
                                <Row className='mb-3'>
                                    <FormIcon title='NSS (Número de Seguro Social)' value={data?.imss} />
                                    <FormIcon title='Infonavit (Ligado al NSS)' value={data?.infonavit} />
                                </Row>
                                <Row className='mb-3'>
                                    <FormIcon title='Fecha de inicio de contrato' value={data?.contract_start_date} />
                                    <FormIcon title='Fecha de finalización de contrato' value={data?.contract_end_date} />
                                </Row>
                            </Col>
                        </Row>


                    </Container>
            }
        </Fragment>
    )
}

export default EmployeeDetails;
