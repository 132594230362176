import { faArrowLeft, faEye } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react';
import { indexEmployee } from '../../../../api/Repse/Employees';
import Filter from '../../../Layouts/Filters';
import ButtonIcon from '../../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../../Layouts/Modal'
import PaginationComp from '../../../Layouts/Pagination';
import SearchComp from '../../../Layouts/Search';
import TableComp from '../../../Layouts/Table';
import EmployeeDetails from './EmployeeDetails';

const Employees = (props) => {
    const { onClose, user, headers } = props;

    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState(false);
    const [title, setTitle] = useState('Lista de empleados');
    const [string, setString] = useState('');

    useEffect(() => {
        getEmployees();
    }, [page, pageSize]);

    const getEmployees = async () => {
        setLoading(true);
        let response = await indexEmployee({ headers, provider_id: user.id, page, pageSize, string });
        setEmployees(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    const changePage = (_page, _pageSize) => {
        setPage(_page);
        setPageSize(_pageSize);
    }

    const viewDetails = (_employee) => {
        setEmployee(_employee);
        setDetails(!details);
        if (!details) setTitle('Detalles del empleado');
        else setTitle('Lista de empleados');
    }

    const searchEmployee = () => {
        getEmployees();
    }
    return (
        <ModalComp
            title={title}
            size='xl'
            onClose={onClose}
            body={<Fragment>
                {
                    details ? <EmployeeDetails
                        employee_id={employee.id}
                        goBack={viewDetails}
                        headers={headers}
                    /> : <Fragment>
                        <Filter
                            items={[
                                <SearchComp
                                    tooltip={'Buscar empleado'}
                                    onClick={searchEmployee}
                                    placeholder={'Buscar [Nombre, RFC, NSS]'}
                                    setString={setString}
                                    string={string}
                                />
                            ]}
                        />
                        <TableComp
                            headers={['#', 'Nombre', 'RFC', 'NSS', 'Infonavit', 'Acciones']}
                            keys={['#', 'name', 'rfc', 'imss', 'infonavit']}
                            body={employees}
                            loading={loading}
                            loadingItems={pageSize}
                            page={page}
                            actions={[
                                { icon: faEye, tooltip: 'Ver detalles de empleado', handleClick: viewDetails }
                            ]}
                        />
                        {/* Paginación */}
                        <PaginationComp totalItems={totalItems} current={page} onChange={changePage} />
                    </Fragment>
                }
            </Fragment>}
            footer={<Fragment>
                {
                    details ?
                        <ButtonIcon name='Regresar' icon={faArrowLeft} onClick={viewDetails} variant='outline-secondary' /> :
                        <ButtonIcon name='Cerrar' icon={faArrowLeft} onClick={onClose} variant='outline-secondary' />
                }
            </Fragment>}
        />
    )
}

export default Employees;
