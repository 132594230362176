import { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { SET_ACTIVE_TAB } from '../../redux/actions/main';
import Main from './fragments/Main'

const ProviderChat = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/chat-provider' });
    }, []);

    return (
        <Fragment>
            <Main />
        </Fragment>
    )
}

export default ProviderChat;