import { Container } from "react-bootstrap";
import Clients from "./fragments/Clients";
import { useEffect, useState } from "react";
import FibraDetails from "./fragments/FibraDetails";
import { useDispatch } from "react-redux";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";

const SuperAdmin = () => {
    const [config, setConfig] = useState(false);
    const dispatch = useDispatch();
    const [fibra, setFibra] = useState({});

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/super-admin' });
    }, []);

    const showConfig = (item) => {
        if (!config) {
            setFibra(item);
        }
        setConfig(!config);
    }

    return (
        <Container fluid>
            {
                config ?
                    <FibraDetails
                        onBack={showConfig}
                        fibra_id={fibra.id}
                        title={`${fibra.code} - ${fibra.name}`}
                    /> :
                    <Clients
                        showConfig={showConfig}
                    />
            }
        </Container>
    );
}

export default SuperAdmin;